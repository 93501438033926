<template lang="pug">
tr
  td {{ number }}
  td  
    template(v-if="item.journey")
      a(
      :href="$router.resolve({name: $ROUTER_NAMES.COURSES, query: {course_day: item.journey.journey_days[0].course_day.id, page_sidebar: 1, page: 1}}).href"
      ).history-course 
        | {{ item.journey.internal_name }}
        br 
        | {{ courseCity}} {{ item.journey.journey_days[0].course_day.date | formattedDate }} {{ item.journey.journey_days[0].course_day.name }}
    template(v-else) -
  td {{ item.location }}
  td {{ item.datetime | formattedDatetime }}
  td {{ item.reservation_code }}
  td {{ item.product_name }}
  td()
    span(v-if="item.result" :style="{color: resultColor}").history-results {{ item.result.results[0] }}
    template(v-else) - 
  td 
    willCome(:will_come_to_course="item.will_come_to_course" @update="updateWillCome")
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
import {mapGetters} from "vuex"

export default {
  // mixins: [errorsMixin],
  // inject: ['svc'],
  props: {
    item: {
      required: true,
      type: Object
    },
    index: Number
  },

  computed: {
    ...mapGetters({
      courses: 'crmCourseCities/items'
    }),

    number() {
      return this.index + 1
    },

    courseCity() {
      if(!this.item.journey) return ''
      return this.courses.find(item => item.ID === this.item.journey.journey_days[0].course_day.city).name
    },

    resultColor() {
      if(!this.item.result) return ''
      if(this.item.result.results[0] === 'V') return '#39ce73'
      else if(this.item.result.results[0] === 'O') return '#ff8348'
      else if(this.item.result.results[0] === 'A') return '#3ab3e8'
      return ''
    }
  },

  methods: {
    async updateWillCome(value) {
      this.$emit('updating:willCome', {ID: this.item.id, value, field: 'will_come_to_course'})
    },
  },

  components: {
    willCome: () => import('@/app/admin/modules/course_managment/components/СMWillCome.vue'),
  }
}
</script>

<style lang="scss" scoped>
.history-results {
  font-size: 18px;
}

.history-course {
  color: $primary-color;
  font-weight: bold;
}
</style>