<template lang="pug">
app-table(
  :headers="headers"
  :list="list"
  item-key="id"
  hide-default-footer
  :server-items-length="list.length"
  :mobile-breakpoint='NaN'
  hide-default-header
  :show-select="false"
  class="app-table elevation-1 v-data-table theme--light"
)
  template(v-slot:item="{item, index}")
    exam-history-item(:item="item" :index="index" @updating:willCome="$emit('updating:willCome', $event)")
</template>

<script>
import {mapGetters} from "vuex"
import examHistoryItem from "./CandidateItemExamHistoryItem.vue"
export default {
  props: {
    list: Array
  },
  data: () => ({
    
    headers: [
      { text: '№', value: 'number', class: '', align: 'center',  sortable: false },
      { text: 'Journey', value: 'journey', class: '', align: 'left',  sortable: false },
      { text: 'Exam location', value: 'exam_location', class: '', align: 'center',  sortable: false },
      { text: 'Exam date & time', value: 'exam_datetime', class: '', align: 'center',  sortable: false },
      { text: 'Reservation code', value: 'reservation_code', class: '', align: 'center',  sortable: false },
      { text: 'Product', value: 'product_name', class: '', align: 'center',  sortable: false },
      { text: 'Results', value: 'product_name', class: '', align: 'center',  sortable: false },
      { text: 'Will come', value: 'will_come', class: '', align: 'center',  sortable: false },
    ],
  }),
  computed: {
    ...mapGetters({
      courses: 'crmCourseCities/items'
    })
  },

  methods: {

  },
  components: {
    appTable: () => import('@/components/global/GroupedTable.vue'),
    examHistoryItem
  }
}
</script>